import React, { useState } from "react"
import { shallowEqual, useSelector } from "react-redux"
import { Card, CardBody, CardTitle, Col, Row, CardText } from "reactstrap"

import FedoraEasyKarma from "../FedoraEasyKarma"
import * as R from "ramda"
import { useHistory } from "react-router"

const Step3 = (props) => {
  const { query } = props

  const [selectedItem, setItem] = useState()
  const history = useHistory()

  const handleStep = (option) => {
    setItem(option)
    history.push(
      `/wizard/${query.duration}/${query.provider}/${encodeURIComponent(option.toLowerCase())}`
    )
  }

  const selectAction = (state) => state.wizard.all_actions

  const actions = useSelector(selectAction, shallowEqual)
  const grouped = R.groupBy((action) => action.provider, actions[query.provider])

  const getData = () => {
    const getUniqueTags = R.pipe(
      R.map((action) => action.tags),
      R.flatten,
      R.uniq
    )
    const tags = getUniqueTags(grouped["easyfix"])
    return tags
  }

  const getLanguageIcon = (lang) => {
    switch (lang) {
      case "Python":
        return <i className="fab fa-python"></i>
      case "JavaScript":
        return <i className="fab fa-js-square"></i>
      case "HTML":
        return <i className="fab fa-html5"></i>
      case "PHP":
        return <i className="fab fa-php"></i>
      default:
        return <i className="fas fa-terminal"></i>
    }
  }

  return (
    <Row>
      {query.provider === "fedora_manual_testing" && (
        <>
          <Col sm="6" className="card-container">
            <Card
              className="cards clickable text-center"
              onClick={() => {
                handleStep("beginner_guide")
              }}>
              <CardBody>
                <h1 className="padded">I'm a complete beginner</h1>
                <CardText> please guide to me this </CardText>
              </CardBody>
            </Card>
          </Col>
          <Col sm="6" className="card-container">
            <Card
              className="cards clickable text-center"
              onClick={() => {
                handleStep("manual_testing")
              }}>
              <CardBody>
                <h1 className="padded">I've already done this</h1>
                <CardText>Show me the test cases </CardText>
              </CardBody>
            </Card>
          </Col>
        </>
      )}

      {query.provider === "fedora_easy_karma" && (
        <Col sm="12">
          <Card>
            <CardBody>
              <FedoraEasyKarma data={grouped["fedora_easy_karma"]} key="karma" />
            </CardBody>
          </Card>
        </Col>
      )}
      {query.provider === "easyfix" && (
        <>
          {getData().map((lang) => (
            <Col sm="6" key={lang.toString()}>
              <Card
                className={
                  "cards clickable text-center" + (selectedItem === lang ? " card-selected" : "")
                }
                onClick={() => {
                  handleStep(lang)
                }}>
                <CardBody>
                  <CardTitle tag="h4">
                    {lang} &nbsp; {getLanguageIcon(lang)}{" "}
                  </CardTitle>
                </CardBody>
              </Card>
            </Col>
          ))}
        </>
      )}
    </Row>
  )
}

export default Step3
