import React from "react"

const SourceLink = (props) => {
  return (
    <a target="_blank" rel="noopener noreferrer" href={props.href} className="smallsuper">
      <i className="fas fa-external-link-alt"></i>
    </a>
  )
}

export default SourceLink
