import React, { useState } from "react"
import { Card, CardBody, Tooltip } from "reactstrap"
import SourceLink from "./SourceLink"

const Timeline = (props) => {
  const [tooltipOpen, setTooltipOpen] = useState(false)

  const toggle = () => setTooltipOpen(!tooltipOpen)

  const get_title = (summary) => {
    summary = summary.toLowerCase()
    if (summary.includes("freeze"))
      return "At the milestone freeze, pushes from the updates-testing to the stable repository are suspended until the release candidate is accepted."
    if (summary === "rawhide")
      return "Next Fedora release hasn't been branched yet from rolling release branch."
    if (summary.includes("branch"))
      return "Next version of Fedora that was 'branched' from the rolling Rawhide tree and in the future will become the next stable Fedora release."
    if (summary.includes("release"))
      return "Release candidate was accepted (violating no milestone criteria) and was released."
  }

  const line = props.data.map((milestone) => {
    return (
      <td key={"line" + milestone.summary}>
        <Line color={milestone.current ? "#2371ae" : "#dcdcdc"} />
      </td>
    )
  })

  const dates = props.data.map((milestone) => {
    return (
      <td key={"dates" + milestone.summary} width="150">
        <b>{milestone.date}</b>
      </td>
    )
  })

  const schedule = props.data.map((milestone) => {
    return (
      <td key={"schedule" + milestone.summary} title={get_title(milestone.summary)} width="150">
        {milestone.summary} <i className="fas fa-question-circle light"></i>
      </td>
    )
  })
  return (
    <div>
      <Card>
        <CardBody>
          <h1 className="text-center padded" width="150">
            Current development schedule{" "}
            <span href="#" id="TooltipExample">
              <i className="fas fa-question-circle light"></i>
            </span>
            <Tooltip
              placement="right"
              isOpen={tooltipOpen}
              target="TooltipExample"
              toggle={toggle}>
              This is a timeline of current Fedora release schedule
            </Tooltip>{" "}
            <SourceLink href="https://fedorapeople.org/groups/schedule/" />
          </h1>
          <div className="table-responsive">
            <table className="timeline">
              <tbody>
                <tr>{line}</tr>
                <tr>{dates}</tr>
                <tr>{schedule}</tr>
              </tbody>
            </table>
          </div>
        </CardBody>
      </Card>
    </div>
  )
}

const Line = (props) => {
  const lineStyle = {
    stroke: props.color,
    strokeWidth: "3",
  }
  return (
    <svg width="150" height="20" version="1.1">
      <line x1="0" y1="50%" x2="100%" y2="50%" style={lineStyle} />
      <circle cx="50%" cy="50%" r="4" style={lineStyle} fill="#f8f9fa" />
    </svg>
  )
}

export default Timeline
