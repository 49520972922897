export const API_CALL_STATUS_VALUES = {
  NOT_STARTED: "NOT_STARTED",
  STARTED: "STARTED",
  RETRYING: "RETRYING",
  FAILED: "FAILED",
  FULFILLED: "FULFILLED",
}

export const MAX_RETRY_LIMIT = 5
export const FIRST_RETRY_GAP = 5 //in seconds
