import React from "react"
import { Row, Card, CardBody } from "reactstrap"
import SourceLink from "./SourceLink"
import BugStats from "./common/BugStats"

const Blockers = (props) => {
  return (
    props.data && (
      <Card className="cards">
        <CardBody>
          <h1 className="text-center padded">
            Fedora {props.release} blockers and FEs{" "}
            <SourceLink href="https://qa.fedoraproject.org/blockerbugs/" />
          </h1>
          <Row>
            <BugStats milestone={"Beta"} stats={props.data.beta} />
            <BugStats milestone={"Final"} stats={props.data.final} />
          </Row>
        </CardBody>
      </Card>
    )
  )
}

export default Blockers
