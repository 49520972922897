import React, { Component } from "react"

class Footer extends Component {
  render() {
    return (
      <div className="footer">
        <div className="container">
          <a href="https://pagure.io/fedora-qa/landingpage">Source</a> on Pagure{" "}
          <span className="float-right">version 0.1.0</span>
        </div>
      </div>
    )
  }
}

export default Footer
