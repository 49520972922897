import React from "react"
import { Row, Col, Card, CardBody, CardHeader } from "reactstrap"
import * as R from "ramda"
import { shallowEqual, useSelector } from "react-redux"
import { FedoraManualTestingItem } from "../FedoraManualTesting"

const Step5 = (props) => {
  const { query } = props

  const selectAction = (state) => state.wizard.all_actions

  const actions = useSelector(selectAction, shallowEqual)
  const grouped = R.groupBy((action) => action.provider, actions["easyfix"])
  const grouped2 = R.groupBy((action) => action.provider, actions["fedora_manual_testing"])

  const getIssues = () => {
    const actions = R.filter(
      (a) => a.extra_data.repo_fullname.includes(decodeURIComponent(query.issues)),
      grouped["easyfix"]
    )
    return actions
  }

  const getFedoraManualTesting = () => {
    const actions = R.filter(
      (a) =>
        a.extra_data.testtype.includes(
          query.issues.charAt(0).toUpperCase() + query.issues.slice(1)
        ),
      grouped2["fedora_manual_testing"]
    )
    return actions
  }

  return (
    <Row>
      {query.issues &&
        getIssues().map((data) => (
          <Col sm="4" md="3" className="card-container" key={data.extra_data.ticket_url}>
            <Card className="cards">
              <CardHeader>{data.extra_data.repo_name}</CardHeader>
              <CardBody>
                <a href={data.extra_data.ticket_url} target="_blank" rel="noopener noreferrer">
                  {data.name}
                </a>
              </CardBody>
            </Card>
          </Col>
        ))}
      {query.issues &&
        getFedoraManualTesting().map((data, index) => (
          <Col key={index} sm="4" md="3" className="card-container">
            <Card className="cards">
              <CardHeader>{data.extra_data.milestone}</CardHeader>
              <CardBody>
                <FedoraManualTestingItem data={data} />
              </CardBody>
            </Card>
          </Col>
        ))}
    </Row>
  )
}

export default Step5
