import React, { useState } from "react"
import { useHistory } from "react-router-dom"
import { Card, CardText, Col, Row } from "reactstrap"

const Step1 = (props) => {
  const [selectedItem, setItem] = useState()
  const history = useHistory()
  const handleStep = (option) => {
    setItem(option)
    history.push(`/wizard/${option}`)
  }

  return (
    <Row className="padded">
      <Col sm="6">
        <Card
          className={
            "cards clickable text-center" + (selectedItem === "few_hours" ? " card-selected" : "")
          }
          body
          type="btn"
          onClick={() => {
            handleStep("few_hours")
          }}>
          <h1 className="padded">
            Few Hours <i className="fas fa-calendar-day"></i>
          </h1>
          <CardText>Task that can take up to Few Hours </CardText>
        </Card>
      </Col>
      <Col sm="6">
        <Card
          className={
            "cards clickable text-center" +
            (selectedItem === "Several_days" ? " card-selected" : "")
          }
          body
          type="btn"
          onClick={() => {
            handleStep("several_days")
          }}>
          <h1 className="padded">
            Several Days <i className="fas fa-calendar-week"></i>
          </h1>
          <CardText>Task that can take up to Several Days </CardText>
        </Card>
      </Col>
    </Row>
  )
}

export default Step1
