import React, { useEffect, useState } from "react"
import {
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Col,
} from "reactstrap"
import { Link } from "react-router-dom"
import Layout from "../layout/Layout"
import Timeline from "./Timeline"
import Events from "./Events"
import Blockers from "./Blockers"
import Minutes from "./Minutes"
import * as R from "ramda"
import { shallowEqual, useDispatch, useSelector } from "react-redux"
import { loadData, toggleLandingPageModal } from "../actions/reduxActions"
import Cookies from "universal-cookie"

const LandingPage = () => {
  const cookies = new Cookies()
  let available_components = ["events", "blockers", "minutes"]
  let enabled_components = cookies.get("landingpage_enabled_components")
  if (enabled_components === undefined) {
    enabled_components = [...available_components]
    cookies.set("landingpage_enabled_components", enabled_components, {
      path: "/",
    })
  }

  const [state, setState] = useState({
    blockerbugs: {},
    devel: 0,
    meetings: [],
    schedule: [],
    last_qa_meeting: {},
    stable: 0,
    config_mode: false,
    enabled_components: enabled_components,
  })

  const selectAction = (state) => state.landing_page.api_call_status
  const api_call_status = useSelector(selectAction, shallowEqual)
  const showModal = useSelector((state) => state.landing_page_controls.showModal, shallowEqual)
  const dispatch = useDispatch()

  const landingPageData = useSelector((state) => state.landing_page)

  useEffect(() => {
    dispatch(loadData())
  }, [dispatch])

  const handleVisibilityChange = (e, item) => {
    let enabled_components = [...state.enabled_components]
    if (enabled_components.includes(item)) {
      enabled_components.splice(enabled_components.indexOf(item), 1)
    } else {
      enabled_components.push(item)
      enabled_components = available_components.filter((c) => enabled_components.includes(c))
    }
    cookies.set("landingpage_enabled_components", enabled_components, {
      path: "/",
    })
    setState({ ...state, enabled_components: enabled_components })
  }

  const toggleModal = () => {
    let modalState = !showModal
    dispatch(toggleLandingPageModal(modalState))
  }

  const available_components_mapper = {
    events: <Events data={landingPageData.meetings} />,
    blockers: <Blockers data={landingPageData.blockerbugs} release={landingPageData.devel} />,
    minutes: <Minutes data={landingPageData.last_qa_meeting} />,
  }
  let components = R.splitEvery(
    2,
    state.config_mode ? available_components : state.enabled_components
  )
  components = components.map((row) => (
    <React.Fragment key={row}>
      {row.map((item) => (
        <Col md={6} className="pad" key={item}>
          {available_components_mapper[item]}
        </Col>
      ))}
    </React.Fragment>
  ))

  return (
    <>
      <Layout apiCallStatus={api_call_status}>
        <div>
          <div className="bluebox">
            <Container>
              <Row>
                <Col md={12}>
                  <Timeline data={landingPageData.schedule} />
                </Col>
              </Row>
            </Container>
          </div>
          <Container>
            <Row>
              {components}
              <Col md={{ size: 12, offset: 2 }} className="text-right">
                <Modal isOpen={showModal} toggle={toggleModal}>
                  <ModalHeader>Visibility Configuration</ModalHeader>
                  <ModalBody>
                    {available_components.map((item) => (
                      <React.Fragment key={item}>
                        <Col sm="12">
                          <div className="custom-control custom-switch">
                            <input
                              type="checkbox"
                              className="custom-control-input"
                              id={item}
                              checked={state.enabled_components.includes(item)}
                              onChange={(e) => handleVisibilityChange(e, item)}
                            />{" "}
                            <label className="custom-control-label clickable" htmlFor={item}>
                              {item === "events"
                                ? "Meetings and testdays in the next 7 days"
                                : item === "minutes"
                                ? "Fedora QA Meeting Minutes"
                                : `Fedora ${landingPageData.devel} Blockers and FEs`}
                            </label>
                          </div>
                        </Col>
                      </React.Fragment>
                    ))}
                  </ModalBody>
                  <ModalFooter>
                    <Button color="primary" onClick={toggleModal}>
                      Close
                    </Button>
                  </ModalFooter>
                </Modal>
              </Col>
            </Row>
            <Row className="d-flex justify-content-center padded">
              <Col md={6} className="padded">
                <Link
                  className="pad btn btn-primary btn-block btn-wrap-text btn-lg active invisible"
                  to="/wizard"
                  role="button">
                  Click here, and help us make Fedora better! <i className="fas fa-search"></i>
                </Link>
              </Col>
            </Row>
          </Container>
        </div>
      </Layout>
    </>
  )
}

export default LandingPage
