import React from "react"
import Layout from "./layout/Layout"
import { Link } from "react-router-dom"
import { Container } from "reactstrap"

const Wizard = () => {
  return (
    <Layout>
      <Container>
        <p className="text-md-center">
          <h2>404</h2>
          <h3>
            <span role="img" aria-label="sad">
              😢
            </span>
          </h3>
          <Link to="/">Go to home page</Link>
        </p>
      </Container>
    </Layout>
  )
}

export default Wizard
