import React, { useEffect, useRef } from "react"
import { Card, CardBody, CardText, Button, CardHeader } from "reactstrap"
import { useOnScreen } from "./Intersection"
import { Link } from "react-scroll"
import clsx from "clsx"

function Tab(props) {
  const {
    data: { title, description, nextBox, id },
    hidden = false,
    check,
    onNext,
    setVisibility,
  } = props

  const ref = useRef()
  const visible = useOnScreen(ref)

  useEffect(() => {
    setVisibility((visibility) => ({ ...visibility, [id]: visible }))
  }, [visible, setVisibility, id])

  return (
    <>
      <Card className={hidden ? "sticky overlay card-container" : "sticky card-container "}>
        <div className="scrollable-container" ref={ref}>
          <CardHeader className={check.includes(id) ? "color" : "cardHeader"}>{title}</CardHeader>
          <CardBody>
            <CardText>{description}</CardText>
            <Link smooth={true} to={nextBox} offset={-160} onClick={() => onNext(nextBox, id)}>
              <Button
                className={clsx({
                  "bottom-padding": true,
                  "float-right": true,
                  color: check.includes(id),
                  cardHeader: hidden,
                })}>
                <i className="fas fa-check"></i>
              </Button>
            </Link>
          </CardBody>
        </div>
      </Card>
    </>
  )
}

export default Tab
