import React from "react"
import { useParams } from "react-router"
import { Breadcrumb, BreadcrumbItem } from "reactstrap"
import { Link } from "react-router-dom"
import { useSelector, shallowEqual } from "react-redux"
import * as R from "ramda"

function WizardNavigation(props) {
  const query = useParams()

  const { step } = props

  const selectAction = (state) => state.wizard.all_actions
  const actions = useSelector(selectAction, shallowEqual)
  const grouped = R.groupBy((action) => action.provider, actions["easyfix"])

  const getTags = () => {
    const getUniqueTags = R.pipe(
      R.map((action) => action.tags),
      R.flatten,
      R.uniq
    )
    const tags = getUniqueTags(grouped["easyfix"])
    return tags
  }

  const selectNavigation = (location) => {
    switch (location) {
      case "Start":
        return `/wizard/`
      case "Duration":
        return `/wizard/${query.duration}`
      case "Provider":
        return `/wizard/${query.duration}/${query.provider}`

      case "Actions":
        return `/wizard/${query.duration}/${query.provider}/${query.action}`

      case "Issues":
        return `/wizard/${query.duration}/${query.provider}/${query.action}`

      default:
        return `/wizard`
    }
  }

  const createBreadcrumbItem = (to, classname, text) => {
    return (
      <BreadcrumbItem key={text}>
        <Link to={selectNavigation(to)} className={classname}>
          {text}
        </Link>
      </BreadcrumbItem>
    )
  }

  const navigators = [
    createBreadcrumbItem("Start", step.slice(-1) > "1" ? "enable" : "disabled", "Start"),
    createBreadcrumbItem(
      "Duration",
      step.slice(-1) > "2" ? "enable" : "disabled",
      query.duration === "few_hours" ? "Few Hours" : "Several Days"
    ),
    createBreadcrumbItem(
      "Provider",
      step.slice(-1) > "3" ? "enable" : "disabled",
      query.provider === "fedora_manual_testing"
        ? "Fedora Manual Testing"
        : query.provider === "easyfix"
        ? "Easyfix"
        : "Bodhi Karma"
    ),
    createBreadcrumbItem(
      "Actions",
      step.slice(-1) > "4" ? "enable" : "disabled",
      query.action === "manual_testing"
        ? "Manual Testing"
        : query.action === "beginner_guide"
        ? "Beginner Guide"
        : query.action
        ? getTags().filter((a) => a.toLowerCase() === decodeURIComponent(query.action))[0] ||
          query.action.charAt(0).toUpperCase() + query.action.slice(1)
        : null
    ),
    createBreadcrumbItem(
      "Issues",
      step.slice(-1) > "5" ? "enable" : "disabled",
      query.action === "manual_testing" && query.issues
        ? query.issues.charAt(0).toUpperCase() + query.issues.slice(1)
        : decodeURIComponent(query.issues)
    ),
  ]

  return (
    <div>
      <Breadcrumb>
        <li>
          <i className="fas fa-angle-double-right"></i> &nbsp;
        </li>
        {navigators.slice(0, Number(step.slice(-1))).map((comp) => comp)}
      </Breadcrumb>
    </div>
  )
}

export default WizardNavigation
