import React, { useState } from "react"
import {
  Card,
  CardHeader,
  CardBody,
  UncontrolledCollapse,
  Row,
  Modal,
  ModalBody,
  ModalFooter,
  Button,
  Col,
} from "reactstrap"
import uniqueID from "../helpers/uniqueID"

const CollapsableCard = (props) => {
  const { title, expand_text, expand_class, children } = props
  const id = uniqueID("collapsable-card-id-")
  return (
    <Card>
      <CardHeader>
        <Row>
          <Col sm={10}>{title}</Col>
          <Col sm={2}>
            <Toggler id={id} color={expand_class} text={expand_text} />
          </Col>
        </Row>
      </CardHeader>
      <UncontrolledCollapse toggler={"#" + id}>
        <CardBody>{children}</CardBody>
      </UncontrolledCollapse>
    </Card>
  )
}

const CollapsableBadge = (props) => {
  const [state, setState] = useState({ showModal: false })

  const toggleModal = () => {
    let modalState = !state.showModal
    setState({
      showModal: modalState,
    })
  }

  const { expand_text, expand_class, children, type } = props
  const id = uniqueID("collapsable-badge-id-")
  return (
    <span>
      <Button
        className={type + " " + type + "-" + expand_class}
        href="#"
        id={id}
        color="primary"
        onClick={toggleModal}>
        {expand_text}
      </Button>{" "}
      <Modal isOpen={state.showModal} toggle={toggleModal}>
        <ModalBody>{children}</ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={toggleModal}>
            Close <i class="fas fa-times-circle"></i>
          </Button>
        </ModalFooter>
      </Modal>
    </span>
  )
}

const ModalBadge = (props) => {
  const { expand_text, expand_class, children, type } = props
  const id = uniqueID("collapsable-badge-id-")
  return (
    <span>
      <Button className={type + " " + type + "-" + expand_class} href="#" id={id} role="button">
        {expand_text}
      </Button>

      <UncontrolledCollapse toggler={"#" + id} className="actions">
        <Card>
          <CardBody>{children}</CardBody>
        </Card>
      </UncontrolledCollapse>
    </span>
  )
}

CollapsableBadge.defaultProps = {
  type: "badge",
  expand_class: "primary",
}

ModalBadge.defaultProps = {
  type: "badge",
  expand_class: "primary",
}

const Toggler = (props) => {
  const { id, text, color } = props
  const classes = "btn btn-sm float-right btn-" + color
  return (
    <button className={classes} href="#" id={id}>
      {text} <i class="fas fa-compass"></i>
    </button>
  )
}

export { CollapsableCard, CollapsableBadge, ModalBadge }
