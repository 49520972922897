import React from "react"
import { Card, CardBody } from "reactstrap"
const Events = (props) => {
  const old = props.data.date

  let date = old.slice(0, 10)
  let time = old.slice(11, 16)

  const timing = time.replace(".", ":")
  const dating = new Date(date).toDateString()

  return (
    <Card className="cards">
      <CardBody className="minutes">
        <h1 className="text-center padded">Fedora QA Meeting Minutes</h1>
        <div className="events minutes">
          <a target="_blank" href={props.data.link} rel="noopener noreferrer">
            Latest minutes from {dating} {timing}
          </a>
          <br />
          Visit{" "}
          <a
            target="_blank"
            href="https://meetbot.fedoraproject.org/sresults/?group_id=fedora-qa&type=team"
            rel="noopener noreferrer">
            meetbot
          </a>{" "}
          to see older.
          <br />
        </div>
      </CardBody>
    </Card>
  )
}

export default Events
