import React from "react"

import { Route, Switch } from "react-router"
import { BrowserRouter } from "react-router-dom"

import Wizard from "./wizard/Wizard"
import LandingPage from "./landingpage/LandingPage"
import NotFound from "./NotFound"
const App = () => {
  return (
    <BrowserRouter basename={window.env.SUBDIR}>
      <Switch>
        <Route path="/" exact component={LandingPage} />
        <Route path="/landingpage" component={LandingPage} />

        <Route
          path="/testing/wizard/:duration/:provider/:action/:issues"
          render={(props) => {
            return <Wizard step="step5" {...props} />
          }}
        />
        <Route
          path="/testing/wizard/:duration/:provider/:action"
          render={(props) => {
            return <Wizard step="step4" {...props} />
          }}
        />

        <Route
          path="/testing/wizard/:duration/:provider"
          render={(props) => {
            return <Wizard step="step3" {...props} />
          }}
        />

        <Route
          path="/testing/wizard/:duration"
          render={(props) => {
            return <Wizard step="step2" {...props} />
          }}
        />

        <Route
          path="/testing/wizard"
          render={(props) => {
            return <Wizard step="step1" {...props} />
          }}
        />
        <Route component={NotFound} />
      </Switch>
    </BrowserRouter>
  )
}

export default App
