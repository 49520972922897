import React, { useRef, useEffect, useState } from "react"
import { useHistory, useLocation } from "react-router-dom"
import queryString from "query-string"

import Tab from "./manualTesting/Tab"

const data = [
  {
    title: "Preperation",
    description:
      " Lorem ipsum some clever text where to get ISO Lorem ipsum some clever text where to get ISO Lorem ipsum some clever text where to get ISO Lorem ipsum some clever text where to get ISO Lorem ipsum some clever text where to get ISO Lorem ipsum some clever text where to get ISO Lorem ipsum some clever text where to get ISO Lorem ipsum some clever text where to get ISO Lorem ipsum some clever text where to get ISO Lorem ipsum some clever text where to get ISO Lorem ipsum some clever text where to get ISOLorem ipsum some clever text where to get ISO Lorem ipsum some clever text where to get ISO Lorem ipsum some clever text where to get ISO Lorem ipsum some clever text where to get ISO Lorem ipsum some clever text where to get ISO Lorem ipsum some clever text where to get ISO Lorem ipsum some clever text where to get ISO Lorem ipsum some clever text where to get ISO Lorem ipsum some clever text where to get ISO Lorem ipsum some clever text where to get ISO Lorem ipsum some clever text where to get ISO",
  },
  {
    title: "Installation",
    description:
      " Lorem ipsum some clever text where to get ISO Lorem ipsum some clever text where to get ISO Lorem ipsum some clever text where to get ISO Lorem ipsum some clever text where to get ISO Lorem ipsum some clever text where to get ISO Lorem ipsum some clever text where to get ISO Lorem ipsum some clever text where to get ISO Lorem ipsum some clever text where to get ISO Lorem ipsum some clever text where to get ISO Lorem ipsum some clever text where to get ISO Lorem ipsum some clever text where to get ISOLorem ipsum some clever text where to get ISO Lorem ipsum some clever text where to get ISO Lorem ipsum some clever text where to get ISO Lorem ipsum some clever text where to get ISO Lorem ipsum some clever text where to get ISO Lorem ipsum some clever text where to get ISO Lorem ipsum some clever text where to get ISO Lorem ipsum some clever text where to get ISO Lorem ipsum some clever text where to get ISO Lorem ipsum some clever text where to get ISO Lorem ipsum some clever text where to get ISO",
  },
  {
    title: "Testing",
    description:
      " Lorem ipsum some clever text where to get ISO Lorem ipsum some clever text where to get ISO Lorem ipsum some clever text where to get ISO Lorem ipsum some clever text where to get ISO Lorem ipsum some clever text where to get ISO Lorem ipsum some clever text where to get ISO Lorem ipsum some clever text where to get ISO Lorem ipsum some clever text where to get ISO Lorem ipsum some clever text where to get ISO Lorem ipsum some clever text where to get ISO Lorem ipsum some clever text where to get ISOLorem ipsum some clever text where to get ISO Lorem ipsum some clever text where to get ISO Lorem ipsum some clever text where to get ISO Lorem ipsum some clever text where to get ISO Lorem ipsum some clever text where to get ISO Lorem ipsum some clever text where to get ISO Lorem ipsum some clever text where to get ISO Lorem ipsum some clever text where to get ISO Lorem ipsum some clever text where to get ISO Lorem ipsum some clever text where to get ISO Lorem ipsum some clever text where to get ISO",
  },
  {
    title: "Reporting",
    description:
      " Lorem ipsum some clever text where to get ISO Lorem ipsum some clever text where to get ISO Lorem ipsum some clever text where to get ISO Lorem ipsum some clever text where to get ISO Lorem ipsum some clever text where to get ISO Lorem ipsum some clever text where to get ISO Lorem ipsum some clever text where to get ISO Lorem ipsum some clever text where to get ISO Lorem ipsum some clever text where to get ISO Lorem ipsum some clever text where to get ISO Lorem ipsum some clever text where to get ISOLorem ipsum some clever text where to get ISO Lorem ipsum some clever text where to get ISO Lorem ipsum some clever text where to get ISO Lorem ipsum some clever text where to get ISO Lorem ipsum some clever text where to get ISO Lorem ipsum some clever text where to get ISO Lorem ipsum some clever text where to get ISO Lorem ipsum some clever text where to get ISO Lorem ipsum some clever text where to get ISO Lorem ipsum some clever text where to get ISO Lorem ipsum some clever text where to get ISO",
  },
]

const addDetailsToData = (data) => {
  return data.map(({ description, title }, index) => {
    return {
      title,
      description,
      id: `box${index + 1}`,
      nextBox: data.length === index + 1 ? "final" : `box${index + 2}`,
    }
  })
}

const createVisibilityObject = (data) => {
  const detailedData = addDetailsToData(data)
  const visibility = {}
  detailedData.forEach((item) => {
    visibility[item.id] = 0
  })
  return visibility
}

const Manual = (props) => {
  const [check, setCheck] = useState([])
  const tabs = addDetailsToData(data) || []
  const [visibility, setVisibility] = useState(createVisibilityObject(data) || {})

  const location = useLocation()
  const history = useHistory()
  const params = queryString.parse(location.search)
  const { tab = "box1" } = params

  const ref = useRef()

  const indexOfMaxValue = (arr) => {
    return arr.reduce((acc, x, i, arr) => (x >= arr[acc] ? i : acc), 0)
  }

  const onNext = (box, currentBox) => {
    if (box === "final") {
      history.push("/wizard/few_hours/fedora_manual_testing/manual_testing")
    } else {
      history.push(`?tab=${box}`)
    }

    if (check.length - (check.indexOf(currentBox) + 1) >= 0 && check.indexOf(currentBox) !== -1) {
      const currValue = check.slice(0, check.indexOf(currentBox))
      setCheck([...currValue])
    }

    if (currentBox && !check.includes(currentBox)) {
      setCheck([...check, currentBox])
    }
  }

  useEffect(() => {
    const maxIndex = indexOfMaxValue(Object.values(visibility))
    history.push(`?tab=box${maxIndex + 1}`)
  }, [history, visibility])

  return (
    <>
      {tabs.map((item, index) => (
        <div id={item.id} className="manual-wrapper" ref={ref} key={item.id}>
          <Tab
            check={check}
            data={item}
            hidden={item.id !== tab}
            onNext={onNext}
            setVisibility={setVisibility}
          />
        </div>
      ))}
    </>
  )
}

export default Manual
