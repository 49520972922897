import React from "react"
import { Table, Col } from "reactstrap"

const BugStats = (props) => {
  const { milestone, stats } = props

  return (
    <Col md={6} className="bugscount">
      <Table>
        <thead>
          <tr>
            <td colSpan="2">
              <b>{milestone}</b>
            </td>
          </tr>
        </thead>
        <tbody>
          {stats && (
            <>
              <tr>
                <td>Proposed Blockers</td>
                <td>{stats.blockers_proposed}</td>
              </tr>
              <tr>
                <td>Accepted Blockers</td>
                <td>{stats.blockers}</td>
              </tr>
              <tr>
                <td>Proposed FEs</td>
                <td>{stats.fe_proposed}</td>
              </tr>
              <tr>
                <td>Accepted FEs</td>
                <td>{stats.fe}</td>
              </tr>
            </>
          )}
        </tbody>
      </Table>
    </Col>
  )
}

export default BugStats
