import React from "react"
import dateFormat from "dateformat"
import SourceLink from "./SourceLink"
import { Card, CardBody, Row, Col } from "reactstrap"

const Events = (props) => {
  const meetings = props.data.map((meeting) => {
    const old = new Date(meeting.start)
    var date = old.getDate()
    var month = old.toLocaleString("default", { month: "short" })
    var time = dateFormat(new Date(old.getTime() - old.getTimezoneOffset() * 60000), "HH:MM")
    if (time.fullday) {
      time = date.slice(0, -5) + "All day"
    }
    return (
      <Event
        key={meeting.link}
        date={date}
        month={month}
        time={time}
        summary={meeting.summary}
        link={meeting.link}
        info={meeting.info}
      />
    )
  })
  return (
    <Card className="cards">
      <CardBody>
        <h1 className="text-center padded">
          Meetings and testdays in the next 7 days{" "}
          <SourceLink href="https://apps.fedoraproject.org/calendar/QA/" />
        </h1>
        <Col md={12} className="padded">
          {meetings}
        </Col>
      </CardBody>
    </Card>
  )
}

const Event = (props) => {
  return (
    <Row>
      <Col md={6}>
        <div className="d-flex flex-row align-items-center event">
          <div className="d-flex flex-column align-items-center date">
            <span className="month"> {props.month}</span>
            <span className="day">{props.date}</span>
          </div>

          <div className="d-flex flex-column align-items-left">
            <span className="title">
              <a className="events" target="_blank" rel="noopener noreferrer" href={props.link}>
                {props.summary}
              </a>
            </span>
            <span className="time">{props.time}</span>
          </div>
        </div>
      </Col>
    </Row>
  )
}

export default Events
