import React from "react"
import { Container } from "reactstrap"
import Step1 from "./step/Step1"
import Step2 from "./step/Step2"
import Step3 from "./step/Step3"
import Step4 from "./step/Step4"
import Step5 from "./step/Step5"
import { useParams } from "react-router-dom"

const WizardStepper = (props) => {
  const { step } = props
  const query = useParams()

  return (
    <Container>
      <div className="wizard">
        {step === "step1" && <Step1 step={step} query={query} {...props} />}
        {step === "step2" && <Step2 step={step} query={query} {...props} />}
        {step === "step3" && <Step3 step={step} query={query} {...props} />}
        {step === "step4" && <Step4 step={step} query={query} {...props} />}
        {step === "step5" && <Step5 step={step} query={query} {...props} />}
      </div>
    </Container>
  )
}

export default WizardStepper
