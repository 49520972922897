import React, { useState } from "react"
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap"
import { CollapsableCard, ModalBadge } from "./components"

export default (props) => {
  let sections = Object.keys(props.data).map((k) => (
    <FedoraManualTestingItemsListSub1 title={k} data={props.data[k]} key={k} />
  ))
  return <div>{sections}</div>
}

const FedoraManualTestingItemsListSub2 = (props) => {
  return (
    <ul>
      {props.data.map((action) => (
        <li>
          <FedoraManualTestingItem data={action} />
        </li>
      ))}
    </ul>
  )
}

export function FedoraManualTestingItemsListSub1(props) {
  var subsections = Object.keys(props.data).map((k) => (
    <li key={"li" + k}>
      <span>{k}</span>
      <FedoraManualTestingItemsListSub2 data={props.data[k]} key={props.data[k]} />
    </li>
  ))
  subsections = <ul>{subsections}</ul>

  var l1 = <div></div>
  switch (props.title) {
    case "Installation":
      l1 = (
        <div>
          The <strong>Installation</strong> testcases generally deal with a part of the
          installation procedure.
          <br />
          You will certainly need an installation ISO, and either a Virtual or Bare-metal machine
          to run the test.
          <br />
          <i>Note:</i> some (not that many, though) of these testcases require Bare-metal machine.
          Be sure to read the Description of the testcase carefully.
        </div>
      )
      break
    case "Desktop":
      l1 = (
        <div>
          The <strong>Desktop</strong> testcases cover basic functionality of the desktop
          environment.
          <br />
          You can either install Fedora onto clean Virtual or Bare-metal machine, or use a Live
          image instead.
        </div>
      )
      break
    case "Base":
      l1 = (
        <div>
          The <strong>Base</strong> testcases cover the system&#x27;s basic functionality just
          after a clean installation.
          <br />
          Most times, you will be asked to perform a clean Fedora installation. You can use either
          Virtual or Bare-metal machine.
          <br />
          <i>Pro tip:</i> using a snapshot of cleanly installed Virtual machine is just fine. No
          need to reinstall for every testcase.
        </div>
      )
      break
    case "Server":
      l1 = (
        <div>
          The <strong>Server</strong> testcases usually require multiple machines to test the
          server-client behaviour, and might feel a bit advanced.
        </div>
      )
      break
    case "Cloud":
      l1 = (
        <div>
          Even thought the <strong>Cloud</strong> testcases are best done in the specific
          environments like EC2 or Openstack, you can also perform them locally using Testcloud.
          <br />
          Have a look at the{" "}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://fedoraproject.org/wiki/Test_Results:Fedora_Current_Cloud#Cloud_Provider_Setup">
            Cloud provider setup
          </a>{" "}
          guides for more details.
        </div>
      )
      break
    default:
      break
  }

  return (
    <CollapsableCard title={l1} expand_text="See" expand_class="primary">
      {subsections}
    </CollapsableCard>
  )
}

export function FedoraManualTestingItem(props) {
  const extra_data = props.data.extra_data

  const tc_url = extra_data.testcase_url
  const name = props.data.name

  const matrix_url = extra_data.matrix_url
  var environment_examples = undefined
  switch (extra_data.testtype) {
    case "Installation":
      environment_examples = (
        <span>
          <code>x86_64</code> or <code>ARM</code>
        </span>
      )
      break
    case "Base":
      environment_examples = (
        <span>
          <code>Workstation</code>, <code>Server</code>, <code>Xfce</code> or <code>Minimal</code>
        </span>
      )
      break
    case "Desktop":
      environment_examples = (
        <span>
          <code>Workstation</code> or <code>KDE</code>
        </span>
      )
      break
    case "Server":
      environment_examples = (
        <span>
          <code>x86_64</code> or <code>aarch64</code>
        </span>
      )
      break
    default:
      break
  }

  const testtype = extra_data.testtype

  return (
    <ModalInfo buttonLabel={name}>
      <ol className="steps">
        <li>
          Identify the testcase and `environment` in our{" "}
          <a target="_blank" rel="noopener noreferrer" href={matrix_url}>
            Tescase matrix
          </a>{" "}
          <ModalBadge expand_text="Not sure what that means?">
            <ul>
              <li>
                Have a look at the matrix. The rows are the testcases, and the columns are the
                environments. Most of the time, these are {environment_examples} for the {testtype}{" "}
                testcases, but you can also encouter a generic <code>Result</code> column, which
                usually means you can use any media/architecture available.
              </li>
              <li>
                For this particular testcase, <code>{extra_data.envs.join(", ")}</code>{" "}
                {extra_data.envs.length > 1 ? "environments are" : "environment is"} missing
                results.
              </li>
            </ul>
          </ModalBadge>
        </li>

        <li>
          Read the{" "}
          <a target="_blank" rel="noopener noreferrer" href={tc_url}>
            Testcase
          </a>{" "}
          briefly, just to have a general idea of what you will be doing.{" "}
          <ModalBadge expand_text="Not making much sense?">
            The testcases are generally split into four sections:
            <ul>
              <li>
                <strong>Description</strong> should give you a general idea of what is being tested
              </li>
              <li>
                <strong>Setup</strong> describes the steps to take before you begin working on the
                testcase
              </li>
              <li>
                <strong>How to test</strong> contains the individual steps to take in order to
                perform the testcase
              </li>
              <li>
                <strong>Expected results</strong> describe what you should check while testing in
                order to decide whether it Passed of Failed
              </li>
            </ul>
          </ModalBadge>
        </li>

        <li>
          Based on the enviromnent, select and download and appropriate ISO{" "}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://fedoraproject.org/wiki/Test_Results:Current_Installation_Test#How_to_test">
            here
          </a>
          .{" "}
          <ModalBadge expand_text="Not sure how?">
            <ul>
              <li>
                Most of the time Workstation Live, or Server DVD are fine, but sometimes there are
                specific products (e.g. Xfce) to be tested
              </li>
              <li>
                Make sure to read about identifying the environment above.{" "}
                <ModalBadge expand_text="See">
                  <ul>
                    <li>
                      Have a look at the matrix. The rows are the testcases, and the columns are
                      the environments. Most of the time, these are {environment_examples} for the{" "}
                      {testtype} testcases, but you can also encouter a generic <code>Result</code>{" "}
                      column, which usually means you can use any media/architecture available.
                    </li>
                    <li>
                      For this particular testcase, <code>{extra_data.envs.join(", ")}</code>{" "}
                      {extra_data.envs.length > 1 ? "environments are" : "environment is"} missing
                      results.
                    </li>
                  </ul>
                </ModalBadge>
              </li>
            </ul>
          </ModalBadge>
        </li>

        <li>
          Study the{" "}
          <a target="_blank" rel="noopener noreferrer" href={tc_url}>
            Testcase
          </a>{" "}
          thoroughly, to be sure you know what to do.{" "}
          <ModalBadge expand_text="Feeling a bit lost?">
            <ul>
              <li>
                The testcase structure is not making sense?{" "}
                <ModalBadge expand_text="Click here">
                  The testcases are generally split into four sections:
                  <ul>
                    <li>
                      <strong>Description</strong> should give you a general idea of what is being
                      tested
                    </li>
                    <li>
                      <strong>Setup</strong> describes the steps to take before you begin working
                      on the testcase
                    </li>
                    <li>
                      <strong>How to test</strong> contains the individual steps to take in order
                      to perform the testcase
                    </li>
                    <li>
                      <strong>Expected results</strong> describe what you should check while
                      testing in order to decide whether it Passed of Failed
                    </li>
                  </ul>
                </ModalBadge>
              </li>
              <li>
                Maybe you are stuck, or just do not understand something? Feel free to ask on our
                IRC channel #fedora-qa at freenode.net{" "}
                <ModalBadge expand_text="Don&#39;t have an IRC client?">
                  If you are not that familiar with IRC, you can use the{" "}
                  <a target="_blank" rel="noopener noreferrer" href="http://webchat.freenode.net">
                    web-interface
                  </a>{" "}
                  just enter a Nickname of your choice, #fedora-qa (including the hash sign) in the
                  Channels field, and click Connect.
                </ModalBadge>
              </li>
            </ul>
          </ModalBadge>
        </li>

        <li>Get your hands dirty, and test the hell out of it!</li>
        <li>
          <ModalBadge expand_class="success" expand_text="Everything went well" type="btn">
            Great! Either use <code>relval report-results</code> on command line (make sure to
            install the <code>relval</code> package first), or modify the{" "}
            <a target="_blank" rel="noopener noreferrer" href={matrix_url}>
              matrix
            </a>{" "}
            directly by clicking on the <code>Edit</code> link next to the Matrice's header, and
            put <code>&#123;&#123;result|pass|YOUR_NAME_HERE|&#125;&#125;</code> in the appropriate
            spot.
          </ModalBadge>

          <ModalBadge expand_class="danger" expand_text="I found a bug!" type="btn">
            Awesome! Ideally read up on https://fedoraproject.org/wiki/How_to_file_a_bug_report
            <br />
            If you don't feel like reading a wall of text, at least pot together a small document
            containing:
            <ul>
              <li>
                Brief description of what went wrong (e.g. "QA:Testcase_dualboot_with_windows -
                Bootloader does not show the Windows option")
              </li>
              <li>
                The name of the ISO image you used (e.g.
                "Fedora-Server-netinst-i386-Rawhide-20181227.n.0.iso")
              </li>
              <li>
                Note what you did, as precisely as possible, in a step-by-step fashion. Even
                details like "Chose Polish as the languae for the installation process" can matter.
              </li>
              <li>
                Try to reproduce the same state again based on the steps above (you can also
                experiment a bit, and try to come up with just the critical steps)
              </li>
              <li>
                Share your notes on the internet (you can use fpaste), and ask for help with filing
                the bug report on our IRC channel #fedora-qa at freenode.net{" "}
                <ModalBadge expand_text="Don&#39;t have an IRC client?">
                  If you are not that familiar with IRC, you can use the web-interface[link] just
                  enter a Nickname of your choice, #fedora-qa (including the hash sign) in the
                  Channels field, and click Connect.
                </ModalBadge>
              </li>
            </ul>
            Once you get the Bug reported, make sure to also submit the result into the testing
            matrix. Either use <code>relval report-results</code> on command line (make sure to
            install the <code>relval</code> package first), or modify the{" "}
            <a target="_blank" rel="noopener noreferrer" href={matrix_url}>
              matrix
            </a>{" "}
            directly by clicking on the <code>Edit</code> link next to the Matrice's header, and
            put <code>&#123;&#123;result|fail|YOUR_NAME_HERE|BUG_NUMBER|&#125;&#125;</code> in the
            appropriate spot.
          </ModalBadge>
        </li>
      </ol>
    </ModalInfo>
  )
}

function ModalInfo(props) {
  const [state, setState] = useState({
    modal: false,
  })

  const toggle = (e) => {
    setState({
      modal: !state.modal,
    })
    e.preventDefault()
  }

  const project = props.buttonLabel.split(":")[0]
  const ticket = props.buttonLabel.split(":")[1] //strip whitespace?

  return (
    <div>
      <a href="/#" onClick={(e) => toggle(e)}>
        {props.buttonLabel}
      </a>
      <Modal isOpen={state.modal} toggle={toggle} className={props.className} size="lg">
        <ModalHeader toggle={toggle}>{project}</ModalHeader>
        <ModalBody>
          <h1>{ticket}</h1>
          {props.children}
        </ModalBody>
        <ModalFooter></ModalFooter>
      </Modal>
    </div>
  )
}
