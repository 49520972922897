import React from "react"
import { Row, Col, Card, CardBody, CardHeader } from "reactstrap"
import { shallowEqual, useSelector } from "react-redux"
import * as R from "ramda"
import { useHistory } from "react-router-dom"
import Manual from "../Manual"

const Step4 = (props) => {
  const { query } = props

  const history = useHistory()

  const handleChange = (option) => {
    if (query.action === "manual_testing") {
      history.push(
        `/wizard/${query.duration}/${query.provider}/${query.action}/${encodeURIComponent(
          option.toLowerCase()
        )}`
      )
    } else {
      history.push(
        `/wizard/${query.duration}/${query.provider}/${query.action}/${encodeURIComponent(option)}`
      )
    }
  }

  const selectAction = (state) => state.wizard.all_actions

  const actions = useSelector(selectAction, shallowEqual)
  const grouped = R.groupBy((action) => action.provider, actions["easyfix"])
  const grouped2 = R.groupBy((action) => action.provider, actions["fedora_manual_testing"])

  const getTags = () => {
    const getUniqueTags = R.pipe(
      R.map((action) => action.tags),
      R.flatten,
      R.uniq
    )
    const tags = getUniqueTags(grouped["easyfix"])
    return tags
  }

  const getData2 = () => {
    const getUniqueTags = R.pipe(
      R.map((action) => action.extra_data.testtype),
      R.flatten,
      R.uniq
    )
    const tags = getUniqueTags(grouped2["fedora_manual_testing"])
    return tags
  }

  const getEasyfix = () => {
    const actions = R.filter(
      (a) =>
        a.tags.includes(
          getTags().filter((item) => item.toLowerCase() === decodeURIComponent(query.action))[0]
        ),
      grouped["easyfix"]
    )
    return actions
  }

  const processData = (data, firstLevelGroupKey) => {
    const getProcessedData = R.groupBy((a) => a.extra_data[firstLevelGroupKey])
    return getProcessedData(data)
  }

  const easyData = processData(getEasyfix() || [], "repo_fullname")

  const getIcontestype = (item) => {
    switch (item) {
      case "Cloud":
        return <i className="fas fa-cloud"></i>
      case "Installation":
        return <i className="fas fa-download"></i>
      case "Desktop":
        return <i className="fas fa-desktop"></i>
      case "Server":
        return <i className="fas fa-server"></i>
      default:
        return <i className="fab fa-fedora"></i>
    }
  }

  const getTesttype = (testtype) => {
    switch (testtype) {
      case "Installation":
        return (
          <div className="text-left">
            The <strong>Installation</strong> testcases generally deal with a part of the
            installation procedure.
            <br />
            You will certainly need an installation ISO, and either a Virtual or Bare-metal machine
            to run the test.
            <br />
            <i>Note:</i> some (not that many, though) of these testcases require Bare-metal
            machine. Be sure to read the Description of the testcase carefully.
          </div>
        )

      case "Desktop":
        return (
          <div className="text-left">
            The <strong>Desktop</strong> testcases cover basic functionality of the desktop
            environment.
            <br />
            You can either install Fedora onto clean Virtual or Bare-metal machine, or use a Live
            image instead.
          </div>
        )

      case "Base":
        return (
          <div className="text-left">
            The <strong>Base</strong> testcases cover the system&#x27;s basic functionality just
            after a clean installation.
            <br />
            Most times, you will be asked to perform a clean Fedora installation. You can use
            either Virtual or Bare-metal machine.
            <br />
            <i>Pro tip:</i> using a snapshot of cleanly installed Virtual machine is just fine. No
            need to reinstall for every testcase.
          </div>
        )

      case "Server":
        return (
          <div className="text-left">
            The <strong>Server</strong> testcases usually require multiple machines to test the
            server-client behaviour, and might feel a bit advanced.
          </div>
        )

      case "Cloud":
        return (
          <div className="text-left">
            Even thought the <strong>Cloud</strong> testcases are best done in the specific
            environments like EC2 or Openstack, you can also perform them locally using Testcloud.
            <br />
            Have a look at the{" "}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://fedoraproject.org/wiki/Test_Results:Fedora_Current_Cloud#Cloud_Provider_Setup">
              Cloud provider setup
            </a>{" "}
            guides for more details.
          </div>
        )

      default:
        break
    }
  }

  return (
    <Row>
      {query.provider === "easyfix" &&
        Object.keys(easyData).map((key) => (
          <Col key={key} sm="6" className="card-container">
            <Card className="cards clickable text-center" onClick={() => handleChange(key)}>
              <CardHeader className="title">{key}</CardHeader>
              <CardBody className="text-left">{easyData[key][0].extra_data.description}</CardBody>
            </Card>
          </Col>
        ))}
      {query.action === "manual_testing" &&
        getData2().map((item) => (
          <Col sm="6" className="card-container" key={item.toString()}>
            <Card
              onClick={() => {
                handleChange(item)
              }}
              className="cards clickable text-center">
              <CardHeader className="title">
                {item} {getIcontestype(item)}{" "}
              </CardHeader>
              <CardBody>{getTesttype(item)}</CardBody>
            </Card>
          </Col>
        ))}
      {query.action === "beginner_guide" && (
        <Col sm="12">
          <Manual query={query} />
        </Col>
      )}
    </Row>
  )
}

export default Step4
