import React, { Component } from "react"
import Masthead from "./Masthead"
import Footer from "./Footer"
import { API_CALL_STATUS_VALUES } from "../constants/ProjectConstants"

class Layout extends Component {
  render() {
    const { apiCallStatus } = this.props
    return (
      <div>
        <Masthead />
        {apiCallStatus !== API_CALL_STATUS_VALUES.FULFILLED ? null : this.props.children}
        <Footer />
      </div>
    )
  }
}

export default Layout
