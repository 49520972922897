import React from "react"
import { Link, useLocation } from "react-router-dom"
import { Container, NavbarBrand, Navbar, Button } from "reactstrap"
import { API_CALL_STATUS_VALUES } from "../constants/ProjectConstants"
import { ROUTE } from "../constants/Routes"
import logo from "./logo.png"
import { loadData, loadWizardData, toggleLandingPageModal } from "../actions/reduxActions"
import { shallowEqual, useDispatch, useSelector } from "react-redux"

const selectWizardFromStore = (state) => state.wizard.api_call_status
const selectLandingPageFromStore = (state) => state.landing_page.api_call_status

const Masthead = () => {
  const location = useLocation()
  const dispatch = useDispatch()

  const wizard_api_call_status = useSelector(selectWizardFromStore, shallowEqual)
  const landing_page_api_call_status = useSelector(selectLandingPageFromStore, shallowEqual)
  const showModal = useSelector((state) => state.landing_page_controls.showModal, shallowEqual)

  const api_call_status = () => {
    if (ROUTE.LANDING_PAGE === location.pathname) {
      return landing_page_api_call_status
    } else if (ROUTE.WIZARD_PAGE === location.pathname) {
      return wizard_api_call_status
    } else {
      return null
    }
  }

  const getRetryFunction = () => {
    if (ROUTE.LANDING_PAGE === location.pathname) {
      return loadData
    } else if (ROUTE.WIZARD_PAGE === location.pathname) {
      return loadWizardData
    } else {
      return null
    }
  }

  const handleRetry = () => {
    const retryFn = getRetryFunction()
    dispatch(retryFn())
  }

  const toggleModal = () => {
    let modalState = !showModal
    dispatch(toggleLandingPageModal(modalState))
  }

  return (
    <Navbar className="masthead">
      <Container>
        <NavbarBrand tag="div">
          <Link to="/">
            <img src={logo} alt="" />
          </Link>
        </NavbarBrand>
        <div>
          {api_call_status() === API_CALL_STATUS_VALUES.STARTED ||
          api_call_status() === API_CALL_STATUS_VALUES.RETRYING ? (
            <>
              {api_call_status() === API_CALL_STATUS_VALUES.RETRYING && (
                <>
                  <span className="fa-lg mr-1 text-danger">
                    <i className="fas fa-exclamation-triangle"></i>
                  </span>
                  <span className="mr-3">Retrying</span>
                </>
              )}
              <span className="fa-lg mr-3">
                <i className="fas fa-spinner fa-pulse"></i>
              </span>
            </>
          ) : null}
          {api_call_status() === API_CALL_STATUS_VALUES.FAILED && (
            <>
              <span className="fa-lg mr-1">
                <i class="fas fa-exclamation-triangle"></i>
              </span>
              <span className="mr-3">Failed to fetch data!</span>
              {handleRetry && (
                <Button outline color="danger" size="sm" onClick={handleRetry}>
                  Retry
                </Button>
              )}
            </>
          )}
          {ROUTE.LANDING_PAGE === location.pathname && (
            <Button color="primary" onClick={toggleModal}>
              <i className="fas fa-cog"></i>
            </Button>
          )}
        </div>
      </Container>
    </Navbar>
  )
}

export default Masthead
