import React from "react"
import { CollapsableBadge, ModalBadge } from "./components"

export default (props) => {
  if (props.data === undefined) return null
  else
    return (
      <div>
        <h1>What is Karma</h1>
        Before a new version of a package is pushed to the Fedora&apos;s Updates repository, it
        needs to be tested and proved functional.{" "}
        <CollapsableBadge expand_text="Tell me more about the process!">
          The updates are kept in{" "}
          <a target="_blank" rel="noopener noreferrer" href="https://bodhi.fedoraproject.org/">
            Bodhi
          </a>
          , which acts as a gatekeeper between new package releases and the stable repositories.{" "}
          Users (or automated systems) can then provide feedback in form of positive/negative
          Karma, marking the update as working (or not) within the scope of their expectations. An
          update can (and usually does) consist of several packages (rpms), that are going through
          the acceptance process together.
        </CollapsableBadge>
        <h1 style={{ marginTop: 1 + "em" }}>Sure, let me do it!</h1>
        <ol className="steps">
          <li>
            Fedora Accounts System (FAS) account is required.{" "}
            <CollapsableBadge expand_text="Don&#39;t have one yet?">
              <ul>
                <li>
                  Visit{" "}
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://admin.fedoraproject.org/accounts/user/new">
                    FAS Sign-up page
                  </a>{" "}
                  and create it.
                </li>
                <li>Note the username and password, you will need it later on.</li>
              </ul>
            </CollapsableBadge>
          </li>
          <li>
            Make sure your system is up-to-date: <code>sudo dnf update --refresh</code>
          </li>
          <li>
            Install fedora-easy-karma tool: <code>sudo dnf install fedora-easy-karma</code>
          </li>
          <li>
            Choose an update to test{" "}
            <CollapsableBadge expand_text="Not sure how?">
              Have a look at the available packages in <code>updates-testing</code> repo, by
              running <code>dnf --enablerepo=updates-testing list --refresh --upgrades</code> and
              choose a package you know.
            </CollapsableBadge>
          </li>
          <li>
            Install the package{" "}
            <code>sudo dnf --enablerepo=updates-testing update PACKAGE_NAME</code>, and test
            whether it works, as you would expect.
            <br />
            <strong>Note:</strong> Other packages from <code>updates-testing</code> repo may be
            installed as dependencies (shown during the installation process). Try to test those
            too, if possible.{" "}
            <CollapsableBadge expand_text="Need some tips?">
              Sometimes, the update associated with the package you just installed fixes some
              specific bugs, or has testcases associated with it.
              <br />
              Run <code>fedora-easy-karma --fas-username=FAS_USERNAME</code>, and look for{" "}
              <code>Bugs</code>, <code>Test Cases</code>, and/or <code>Notes</code> sections in the
              detailed output.{" "}
              <ModalBadge expand_text="Want something a bit nicer?">
                We already mentioned Bodhi, several times. Instead of using the{" "}
                <code>fedora-easy-karma</code> interface for submitting Karma and/or learning about
                the Update, you can visit Bodhi directly.
                <br />
                <code>fedora-easy-karma</code> shows the URL of the relevant update near the bottom
                of the text output - look for URL like{" "}
                <code>https://bodhi.fedoraproject.org/updates/FEDORA-2019-00870e8bfc</code>.
                <br />
                You can have a look at other people's comments, the afore-mentioned Bugs or Test
                Cases, and even submit the Karma directly. Just use your FAS credentials to log-in.
              </ModalBadge>
            </CollapsableBadge>
          </li>
          <li>
            Run <code>fedora-easy-karma --fas-username=FAS_USERNAME</code> and report what you
            found out.{" "}
            <CollapsableBadge expand_text="New to fedora-easy-karma?">
              It identifies the packages installed from the <code>updates-testing</code>{" "}
              repository, and matches them to the updates in Bodhi.
              <br />
              You are then presented with the relevant updates one-by-one, to submit Karma and a
              comment.{" "}
              <ModalBadge expand_text="Not sure what Karma means?">
                <ol>
                  <li>
                    Enter <code>1</code> to mark positive karma (the update works just fine, as far
                    as you can tell), or <code>-1</code> to mark the package as broken. <br />
                    Not sure you can judge the package's state? Have a look at the{" "}
                    <a
                      target="_blank"
                      href="https://fedoraproject.org/wiki/QA:Update_feedback_guidelines"
                      rel="noopener noreferrer">
                      Update Feedback Guidelines
                    </a>
                    <br />
                    Still not sure? Just press <code>Enter</code> to skip it.
                  </li>
                  <li>
                    Add a comment - even if it works, try to describe what you tested. It can be as
                    easy as <code>I tried openning and editing a document, and it worked.</code>{" "}
                    when you test a text-editor, or{" "}
                    <code>I opened a couple of tabs, and browsed random pages.</code> for a
                    web-browser.
                    <br />
                    Absolutely make sure to provide reasonable amount of detail, when submitting
                    negative karma - ideally, you should also create a bugreport, and reference it
                    in the comment.
                  </li>
                </ol>
              </ModalBadge>
            </CollapsableBadge>
          </li>
          <li>
            Restore the stable-packages on your system by running <code>sudo dnf distro-sync</code>
          </li>
        </ol>
      </div>
    )
}
