import React, { useEffect } from "react"
import Layout from "../layout/Layout"
import { Col, Container, Row } from "reactstrap"

import { useDispatch, useSelector, shallowEqual } from "react-redux"
import { loadWizardData } from "../actions/reduxActions"
import WizardStepper from "./WizardStepper"
import { useParams } from "react-router"
import WizardNavigation from "./WizardNavigation"

const Wizard = (props) => {
  const { step } = props
  const query = useParams()
  const selectAction = (state) => state.wizard.api_call_status

  const api_call_status = useSelector(selectAction, shallowEqual)

  const dispatch = useDispatch()

  useEffect(() => {
    const getData = () => {
      dispatch(loadWizardData())
    }
    getData()
  }, [dispatch])

  return (
    <Layout apiCallStatus={api_call_status}>
      <Container>
        <Row className="justify-content-center align-items-center">
          <div className="wizard">
            <WizardNavigation step={step} />
            <WizardStepper step={step} />

            {query.issues || query.provider === "fedora_easy_karma" ? (
              <Col md="12">
                <h5 className="padded text-center">
                  None of the above looks interesting to you? We'd be happy if you looked around at{" "}
                  <a href="https://whatcanidoforfedora.org/">https://whatcanidoforfedora.org/</a>!
                </h5>
              </Col>
            ) : null}
          </div>
        </Row>
      </Container>
    </Layout>
  )
}

export default Wizard
