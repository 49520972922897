import { stringify } from 'query-string';

export const sendRequest = ({
  url,
  method = "GET",
  body,
  queryParams,
}) => {
  const options = {
    method: method,
    body: JSON.stringify(body)
  };

  if (queryParams) {
    url = `${url}?${stringify(queryParams)}`;
  }

  return fetch(url, options).then(res => {
    if (res.ok) {
      return res.json();
    } else {
      return res.json().then((json) => {
        return Promise.reject({
          status: res.status,
          ok: false,
          message: json.message,
          body: json
        });
      });
    }
  });
};