import React, { useState } from "react"
import { shallowEqual, useSelector } from "react-redux"
import { Card, CardBody, CardTitle, CardText, Row, Col } from "reactstrap"
import { useHistory } from "react-router-dom"

const Step2 = (props) => {
  const { query } = props

  const [selectedItem, setItem] = useState()
  const history = useHistory()

  const handleStep = (option) => {
    setItem(option)
    history.push(`/wizard/${query.duration}/${option}`)
  }

  const selectProvider = (state) => state.wizard.providers

  const providers = useSelector(selectProvider, shallowEqual)

  const fewHours = providers.filter((provider) => provider.duration === 3)
  const severalDays = providers.filter((provider) => provider.duration === 72)

  const getIcon = (type) => {
    switch (type) {
      case "fedora_manual_testing":
        return <i className="fas fa-user-check"></i>
      case "easy_fix":
        return <i className="fas fa-magic"></i>
      default:
        return <i className="fab fa-fedora"></i>
    }
  }

  return (
    <Row className="justify-content-center">
      {query.duration === "several_days" && (
        <>
          {severalDays.map((item) => (
            <Col sm="6" key={item.provider}>
              <Card
                className={
                  "cards clickable text-center" +
                  (selectedItem === item.provider ? " card-selected" : "")
                }
                onClick={() => {
                  handleStep(item.provider)
                }}>
                <CardBody>
                  <CardTitle tag="h4">
                    {item.name} {getIcon(item.provider)}
                  </CardTitle>
                  <CardText>{item.description}</CardText>
                </CardBody>
              </Card>
            </Col>
          ))}
        </>
      )}

      {query.duration === "few_hours" && (
        <>
          {fewHours.map((item) => (
            <Col sm="6" key={item.provider}>
              <Card
                className={
                  "cards clickable text-center" +
                  (selectedItem === item.provider ? " card-selected" : "")
                }
                onClick={() => {
                  handleStep(item.provider)
                }}>
                <CardBody>
                  <CardTitle tag="h4">
                    {item.name} {getIcon(item.provider)}
                  </CardTitle>
                  <CardText>{item.description}</CardText>
                </CardBody>
              </Card>
            </Col>
          ))}
        </>
      )}
    </Row>
  )
}

export default Step2
