import ActionTypes from "../constants"
import {
  API_CALL_STATUS_VALUES,
  MAX_RETRY_LIMIT,
  FIRST_RETRY_GAP,
} from "../constants/ProjectConstants"
import { landingPageApi } from "../api/landingPage"
import { loadWizardDataApi } from "../api/wizard"

export const loadDataResp = (payload) => ({
  type: ActionTypes.LOAD_DATA_RESP,
  payload: payload,
})

export const loadData =
  (payload, retryLimit = MAX_RETRY_LIMIT, secondsToWait = FIRST_RETRY_GAP) =>
  (dispatch) => {
    dispatch({
      type: ActionTypes.LOAD_DATA,
      payload: payload,
    })
    dispatch(updateLandingPageApiCallStatus(API_CALL_STATUS_VALUES.STARTED))
    landingPageApi()
      .then((data) => {
        dispatch(loadDataResp(data))
        dispatch(updateLandingPageApiCallStatus(API_CALL_STATUS_VALUES.FULFILLED))
      })
      .catch((error) => {
        if (retryLimit > 0) {
          dispatch(updateLandingPageApiCallStatus(API_CALL_STATUS_VALUES.RETRYING))
          console.log("Failed to fetch, retrying", MAX_RETRY_LIMIT - retryLimit)
          window.setTimeout(() => {
            dispatch(loadData(payload, retryLimit - 1, secondsToWait * 2))
          }, secondsToWait * 1000)
        } else {
          dispatch(updateLandingPageApiCallStatus(API_CALL_STATUS_VALUES.FAILED))
          console.error("Error:", error)
        }
      })
  }

export const loadWizardDataResp = (payload) => ({
  type: ActionTypes.LOAD_WIZARD_DATA_RESP,
  payload: payload,
})

export const loadWizardData =
  (payload, retryLimit = MAX_RETRY_LIMIT, secondsToWait = FIRST_RETRY_GAP) =>
  (dispatch) => {
    dispatch({
      type: ActionTypes.LOAD_WIZARD_DATA,
      payload: payload,
    })
    dispatch(updateWizardApiCallStatus(API_CALL_STATUS_VALUES.STARTED))
    loadWizardDataApi()
      .then((data) => {
        dispatch(loadWizardDataResp(data))
        dispatch(updateWizardApiCallStatus(API_CALL_STATUS_VALUES.FULFILLED))
      })
      .catch((error) => {
        if (retryLimit > 0) {
          dispatch(updateWizardApiCallStatus(API_CALL_STATUS_VALUES.RETRYING))
          console.log("Failed to fetch, retrying", MAX_RETRY_LIMIT - retryLimit)
          window.setTimeout(() => {
            dispatch(loadWizardData(payload, retryLimit - 1, secondsToWait * 2))
          }, secondsToWait * 1000)
        } else {
          dispatch(updateWizardApiCallStatus(API_CALL_STATUS_VALUES.FAILED))
          console.error("Error:", error)
        }
      })
  }

export const updateLandingPageApiCallStatus = (status) => ({
  type: ActionTypes.UPDATE_LANDING_PAGE_API_CALL_STATUS,
  payload: status,
})

export const updateWizardApiCallStatus = (status) => ({
  type: ActionTypes.UPDATE_WIZARD_API_CALL_STATUS,
  payload: status,
})

export const toggleLandingPageModal = (status) => ({
  type: ActionTypes.TOGGLE_LANDING_PAGE_MODAL,
  payload: status,
})
