import ActionTypes from "../constants"

const defaultState = {
  landing_page: {
    blockerbugs: {},
    devel: 0,
    meetings: [],
    schedule: [],
    last_qa_meeting: {},
    stable: 0,
    config_mode: false,
    enabled_components: ["events", "blockers", "minutes"],
    api_call_status: null,
  },
  wizard: {
    actions: [],
    providers: [],
    all_actions: [],
    api_call_status: null,
  },
  landing_page_controls: {
    showModal: false,
  },
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case ActionTypes.LOAD_DATA_RESP:
      return {
        ...state,
        landing_page: {
          ...state.landing_page,
          ...action.payload,
        },
      }
    case ActionTypes.LOAD_WIZARD_DATA_RESP:
      return {
        ...state,
        wizard: {
          ...state.wizard,
          providers: action.payload.providers,
          all_actions: action.payload.actions,
        },
      }

    case ActionTypes.UPDATE_WIZARD_API_CALL_STATUS:
      return {
        ...state,
        wizard: {
          ...state.wizard,
          api_call_status: action.payload,
        },
      }
    case ActionTypes.UPDATE_LANDING_PAGE_API_CALL_STATUS:
      return {
        ...state,
        landing_page: {
          ...state.landing_page,
          api_call_status: action.payload,
        },
      }
    case ActionTypes.TOGGLE_LANDING_PAGE_MODAL:
      return {
        ...state,
        landing_page_controls: {
          showModal: action.payload,
        },
      }
    default:
      return {
        ...state,
      }
  }
}
